<template>
  <div class="relationUsContent">
    <div style="overflow-y: scroll;height:100%;width:100%" ref="GoTopContent">
      <Title></Title>

      <div class="banner">
        <h4 style="font-size: 46px; font-weight: 600; padding: 0px 0 40px">
          联系我们
        </h4>
        <button @click="$router.push('/freeTrial')">立即预约演示</button>
      </div>

      <div class="companyDes center">
        <div class="companyContent">
          <div class="companyLeft">
            <img src="@/assets/about/company.png" alt="" style="width:460px;height:100%;border-radius: 10px 0 0 10px;">
            <!-- <div class="companyLeftMask"></div> -->
          </div>
          <div class="companyRigth">
            <div style="font-size:24px;line-height: 32px;text-shadow: 0px 8px 24px rgba(0,0,0,0.15);">北京易普行科技有限公司</div>
            <span style="width: 50px;height: 6px;background: #D70C0C;box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.15);margin:20px 0;"></span>
            <div style="color:#444">
              <span>总部地址：</span>
              <span>北京市海淀区上地三街9号D座3(2)层D311</span>
            </div>
            <div style="padding:20px 0;color:#444">
              <span>电&emsp;&emsp;话：</span>
              <span>010-60780718</span>
            </div>
            <div style="color:#444">
              <span>邮&emsp;&emsp;箱：</span>
              <span>market@epxing.com</span>
            </div>
          </div>
        </div>

        <h4 class="descH4">分支机构</h4>
        <span class="line"></span>
        <div style="font-size: 24px;color:#222;padding:20px 0 40px">
          7家分公司，15个办事处，第一时间为您提供专业服务
        </div>
        <div class="jgContent">
          <div v-for="(item,index) in jgData" :key="index" class="jgItem">
            <div class="valueItemMask"></div>
            <div class="jgItemTitle">{{item.title}}</div>
            <div class="jgItemLine"></div>
            <div class="jgItemText">地址：{{item.address}}</div>
            <div class="jgItemText">电话：{{item.phone}}</div>
          </div>
        </div>
      </div>

      <img src="@/assets/RelationUs/map.png" alt="" style="width:100%;display:block">

      <Foot></Foot>
    </div>

    <BackTop class="backTop"></BackTop>
  </div>
</template>

<script>
export default {
  name: 'RelationUs',
  data () {
    return {
      jgData: [
        { title: '武汉分公司', address: '武汉市武昌区中南路2-6号中建广场B座22层A室', phone: '13986139776' },
        { title: '广州分公司', address: '广东省广州市海珠区江南大道中180号富力天域中心3203', phone: '13510559311' },
        { title: '南京分公司', address: '南京市玄武区珠江路657号锦创数字产业园C座517', phone: '18516551136' },
        { title: '上海分公司', address: '上海市普陀区中山北路2000号中期大厦925室', phone: '18516551136' },
        { title: '西安分公司', address: '陕西省西安市碑林区中贸广场12号楼2803', phone: '15001241965' },
        { title: '成都分公司', address: '成都市金牛区花照壁西顺街388号龙湖紫宸香颂四期3栋1单元804', phone: '18680763410' },
        { title: '长沙办事处', address: '湖南省长沙市岳麓区潇湘中路269号', phone: '13986139776' },
        { title: '重庆办事处', address: '重庆市沙坪坝区永年路101号', phone: '18680763410' },
        { title: '深圳办事处', address: '广东省深圳市罗湖区东湖路6号', phone: '13510559311' },
        { title: '长春办事处', address: '吉林省长春市前进大街2699号', phone: '13331150113' },
        { title: '沈阳办事处', address: '辽宁省沈阳市皇姑区郡原悦城', phone: '13331150113' },
        { title: '哈尔滨办事处', address: '黑龙江省哈尔滨市松北区松浦街道观江国际A区', phone: '13331150113' },
        { title: '杭州分公司', address: '浙江省杭州市北软路万家名城1号', phone: '17316088617' },
        { title: '济南办事处', address: '山东省济南市历下区工业南路55号', phone: '17600895431' },
        { title: '青岛办事处', address: '青岛市城阳区夏庄街道银河路666号', phone: '17600895431' },
        { title: '郑州办事处', address: '河南省郑州市金水区农业路与天明路交叉口索克时代大厦A803', phone: '13986139776' },
        { title: '昆明办事处', address: '云南省昆明市官渡区紫金中心', phone: '17600856333' },
        { title: '合肥办事处', address: '安徽省合肥市包河区大摩广场4号楼', phone: '18516551136' }
      ]
    }
  },
  methods: {
    getHeight () {
      if (window.innerWidth <= 800) {
        this.$router.push('/moRelationUs')
      }
    }
  },
  created () {
    // 监听可视窗口宽度
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  }

}
</script>

<style scoped lang='scss'>
.relationUsContent{
  position: relative;
  top: 0;
  left: 0;
  min-width: 1200px;
  height: 100vh;
  overflow: hidden;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  object-fit: cover;
}
li {
  list-style: none;
}
.line {
  display: inline-block;
  width: 40px;
  height: 6px;
  background: #d70c0c;
  border-radius: 3px;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.descH4 {
  font-size: 36px;
  font-weight: 500;
  color: #222222;
  padding: 60px 0 20px;
}

.banner {
  width: 100%;
  height: 460px;
  background: url("~@/assets/RelationUs/banner.png") center no-repeat;
    background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.banner button {
  width: 200px;
  height: 60px;
  background: linear-gradient(135deg, #ff8484 0%, #c61f1f 100%);
  border-radius: 34px;
  font-size: 16px;
  color: #fff;
  border: 0;
}
.banner button:hover {
  box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
  cursor: pointer;
}

.companyDes{
  width: 100%;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}
.companyContent{
  width: 1200px;
  height: 300px;
  display: flex;
  align-items: center;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.3);
  margin: 40px 0;
  border-radius: 10px;
}
.companyLeft{
  position: relative;
  top: 0;
  left: 0;
  width: 460px;
  height: 100%;
  border-radius: 10px 0 0 10px;
}
.companyLeftMask{
  width: 460px;
  height: 100%;
  border-radius: 10px 0 0 10px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(45, 45, 48, 0.15);
}
.companyRigth{
  width: 740px;
  height: 100%;
  background: url('~@/assets/product/productPt/ptBj.jpg') center no-repeat;
  background-size: 100% 100%;
  border-radius: 0px 10px 10px 0px;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
}

.jgContent{
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 40px;
}
.jgItem{
  position: relative;
  top: 0;
  left: 0;
  width: 386px;
  height: 180px;
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 4px;
  cursor: pointer;
  padding: 24px;
  box-sizing: border-box;
  border: 1px solid rgba(220, 223, 230, 0.5);
}
.valueItemMask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.jgItemTitle{
  font-size: 20px;
  font-weight: 600;
  color: #222222;
  line-height: 28px;
}
.jgItemLine{
  width: 30px;
  height: 2px;
  background: #D70C0C;
  margin: 14px 0;
}
.jgItemText{
  font-size: 16px;
  font-weight: 400;
  color: #444444;
  line-height: 26px;
}
.jgItem:hover{
  box-shadow: 0px 8px 20px 0px rgba(173,0,0,0.3);
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  .valueItemMask{
    display: block;
    background: url('~@/assets/hoverBg.png') center no-repeat;
    background-position: top left;
  }
  .jgItemTitle,.jgItemText{
    color: #fff;
  }
  .jgItemLine{
    background-color: #fff;
  }
}
</style>
